import { render, staticRenderFns } from "./CardType1.vue?vue&type=template&id=c29fcedc&scoped=true&"
import script from "./CardType1.vue?vue&type=script&lang=js&"
export * from "./CardType1.vue?vue&type=script&lang=js&"
import style0 from "./CardType1.vue?vue&type=style&index=0&id=c29fcedc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c29fcedc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
